import { useMediaQuery } from '@vueuse/core';

export function useMq() {
  const {
    isMobile,
    isTablet,
    isDesktop,
    isMobileOrTablet,
  } = useDevice();

  const isExtraSmallScreen = ref(isMobile);
  const isSmallScreen = ref(isTablet);
  const isMediumScreen = ref(isTablet);
  const isLargeScreen = ref(isDesktop);
  const isSmallOrMediumScreen = ref(isMobileOrTablet);
  const isBelowMediumScreen = ref(isMobileOrTablet);

  watch(useMediaQuery('(max-width: 639px)'), (value) => (isExtraSmallScreen.value = value));
  watch(useMediaQuery('(min-width: 640px) and (max-width: 767px)'), (value) => (isSmallScreen.value = value));
  watch(useMediaQuery('(min-width: 768px) and (max-width: 1023px)'), (value) => (isMediumScreen.value = value));
  watch(useMediaQuery('(min-width: 1024px)'), (value) => (isLargeScreen.value = value));
  watch(useMediaQuery('(max-width: 1023px)'), (value) => (isSmallOrMediumScreen.value = value));
  watch(useMediaQuery('(max-width: 767px)'), (value) => (isBelowMediumScreen.value = value));

  onMounted(() => {
    isExtraSmallScreen.value = toValue(useMediaQuery('(max-width: 639px)'));
    isSmallScreen.value = toValue(useMediaQuery('(min-width: 640px) and (max-width: 767px)'));
    isMediumScreen.value = toValue(useMediaQuery('(min-width: 768px) and (max-width: 1023px)'));
    isLargeScreen.value = toValue(useMediaQuery('(min-width: 1024px)'));
    isSmallOrMediumScreen.value = toValue(useMediaQuery('(max-width: 1023px)'));
    isBelowMediumScreen.value = toValue(useMediaQuery('(max-width: 767px)'));
  });

  return {
    isExtraSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isSmallOrMediumScreen,
    isBelowMediumScreen,
  };
}
